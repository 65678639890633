class CategoryHeaders {

  constructor() {
    this.categories = document.querySelector('.categories');
    this.events();
  }

  events() {
    this.categories.addEventListener('click', (ev) => {
      if (ev.target.classList.contains('category__header')) {
        ev.target.querySelector('.angle').classList.toggle('angle--open');
        ev.target.parentElement.classList.toggle('category--open');
      }
      if (ev.target.parentElement.classList.contains('category__header')) {
        ev.target.classList.toggle('angle--open');
        ev.target.parentElement.parentElement.classList.toggle('category--open');
      }
    })

  }
}

export default CategoryHeaders;
