import DietButton from './modules/DietButton';
import CategoryHeaders from './modules/CategoryHeaders';
import AllergenListButton from './modules/AllergenListButton';
import HideMessageBox from './modules/HideMessageBox';
import DietPopup from './modules/DietPopup';
// import bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.bundle.min';
// import bootstrapSlim from '../../node_modules/jquery/dist/jquery.slim.min';

/* Instansier objekter */
const dietButton = new DietButton();
const categoryHeaders = new CategoryHeaders();
const allergenListButton = new AllergenListButton();
const hideMessageBox = new HideMessageBox();
const dietPopup = new DietPopup();