class DietButton {

  constructor() {
    this.openBtn = document.querySelector('#dietOpenBtn');
    this.exitBtn = document.querySelector('#dietOpenBtn');
    this.dietEl = document.querySelector('.diets');
    this.dietChoices = document.querySelector('.diets__choices');
    this.currentDiet = [];
    this.events();
  }

  events() {
    this.dietEl.addEventListener('click', (ev) => {
      if (ev.target === this.openBtn) {
        this.dietEl.classList.toggle('diets--open');
        ev.target.querySelector('.angle').classList.toggle('angle--open');
      } else if (ev.target.parentElement === this.openBtn) {
        this.dietEl.classList.toggle('diets--open');
        ev.target.classList.toggle('angle--open');
      }
      if (ev.target.parentElement === this.dietChoices) {
        const li = ev.target;
        if (li.classList.contains('diet--active')) {
          this.removeElement(this.currentDiet, li.innerHTML.toLowerCase());
          li.classList.remove('diet--active');
        } else {
          this.currentDiet.push(li.innerHTML.toLowerCase());
          li.classList.add('diet--active');
        }
        this.filterProducts();
        this.fadeEmptyCategories();
      }
    })
  }

  filterProducts() {
    const products = document.querySelectorAll('.product');

    if (this.currentDiet.length == 0) {
      products.forEach(product => {
        product.classList.remove('d-none');
      });
      return;
    }

    products.forEach(product => {
      const found = this.currentDiet.every(r =>
        Array.from(product.classList).indexOf(r) >= 0
      );
      if (found) {
        product.classList.remove('d-none');
      } else {
        product.classList.add('d-none');
      }
    })

  }

  fadeEmptyCategories() {
    let categories = document.querySelectorAll('.category__header');
    categories.forEach(category => {
      let products = category.nextElementSibling.children;
      let hidden = 0;
      let hide = Array.from(products).forEach(product => {
        if (product.classList.contains('d-none')) {
          hidden++;
        }
      });
      if (hidden === products.length) {
        category.style.opacity = "0.5";
        category.querySelector('.angle').classList.add('d-none');
      } else {
        category.style.opacity = "1";
        category.querySelector('.angle').classList.remove('d-none');
      }
    })
  }

  removeElement(array, elem) {
    const index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
}

export default DietButton;
